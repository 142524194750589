
.drawer-mobile {
    display: flex;
    width: 80%;
    max-width: 425px;
    padding-bottom: 50px;
}


.partially-checked {
    background-color: rgba(47, 97, 224, 0.05);
}

.bold {
    font-weight: bold;
}

.divider {
    margin-left: 16px;
    margin-right: 16px;
}

