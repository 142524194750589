.ol-map {
  height: calc(100vh - 56px);
  width: 100%;
}
.ol-control {
  position: absolute;
  border-radius: 8px;
}

.ol-control button:not(.btn):hover {
  cursor: pointer;
  opacity: 0.5;
}

.ol-control button:not(.btn):focus {
  outline: none;
}

.ol-control button:not(.btn) {
  vertical-align: top;
  height: 48px;
  width: 48px;
  color: #5D6071;
  background-color: white;
  border: 3px solid #5D6071;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
}

.ol-control button.active {
  background-color: #5D6071;
  color: white;
}

.ol-control.drawer-open {
  transition: all 0.2s;
}

.ol-reset {
  top: 24px;
}

.ol-info {
  bottom: 0;
  margin-bottom: 12px;
  right: 32px;
}

.ol-zoom {
  top: 88px;
}

.ol-zoom-in {
  height: 48px;
  width: 48px;
  background: white url("../../assets/images/map/icon_zoom_in.svg") no-repeat center;
  border: none;
  display: block;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  color: transparent;
}

.ol-zoom-out {
  margin-top: 16px;
  height: 48px;
  width: 48px;
  background: white url("../../assets/images/map/icon_zoom_out.svg") no-repeat center;
  border: none;
  display: block;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  color: transparent;
}

.ol-mouse-position {
  background: rgba(0, 60, 136, 0.3);
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  bottom: 0;
  margin-bottom: 12px;
  position: absolute;
  margin-left: 50%;
  position: absolute;
  width: 140px;
  height: 30px;
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  transform: translate(-50%, 0);
}

.ol-scale-line {
  background: rgba(0, 60, 136, 0.3);
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  bottom: 0;
  left: 32px;
  position: absolute;
  width: 65px;
  height: 30px;
  margin-bottom: 12px;
}

.ol-scale-line-inner {
  color: white;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  padding: 2px;
  margin: auto;
  will-change: contents, width;
  transition: all 0.25s;
}

.ol-scale-line-inner:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 50%;
  width: inherit;
  border-left: 2px solid white;
  border-right: 2px solid white;
  transform: translateY(-50%);
  height: 20px;
  text-align: center; 
  transform: translate(-50%, 0);
}

.ol-scale-line-inner:after {
  content: '';
  position: absolute;
  top: 24px;
  left: 50%;
  width: inherit;
  border-top: 2px solid white;
  text-align: center; 
  transform: translate(-50%, 0);
}

.ol-scale-text {
  background: none;
  bottom: 58px;
  left: 32px;
  width: 65px;
  height: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
}

.ol-scale-text.ol-in-dialog {
  bottom: 147px;
}

.ol-scale-select {
  position: absolute;
  left: 220px;
  bottom: 24px;
  width: 200px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 2;
  transition: all 0.2s;
}

.ol-layer-switcher {
  left: 32px;
  top: 24px;
}

.ol-search {
  left: 32px;
  top: 88px;
}

.ol-locate {
  left: 32px;
  top: 152px;
}

.ol-draw-buttons {
  position: absolute;
  left: 55px;
  top: 0px;
  width: 48px;
  background-color: #091E42;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 2;
}

.ol-draw-buttons button:not(.btn) {
  background-color: #091E42;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.ol-draw-text-panel {
  position: absolute;
  left: 55px;
  top: 161px;
  width: 120px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: none;
  z-index: 2;
}

.ol-measure {
  left: 32px;
  top: 216px;
}

.ol-measure-panel {
  position: absolute;
  left: 55px;
  top: 0px;
  width: 176px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 2;
}

.ol-select {
  left: 32px;
  top: 344px;
}

.ol-select-panel {
  position: absolute;
  left: 55px;
  top: 0px;
  width: 176px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 2;
}

.ol-tooltip {
  position: relative;
  background: rgba(9,30,66, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #091E42;
  color: white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #091E42;
}

.ol-coordinates-tooltip {
  background: white url("../../assets/images/map/icon_copy_clipboard.svg") no-repeat 3% center; 
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  line-height: 32px;
  font-size: 12px;
  z-index: 2;
  padding-right: 14px;
  padding-left: 38px;
}

.ol-search,
.ol-mouse-position, 
.ol-mouse-position,
.ol-scale-line,
.ol-scale-text,
.ol-layer-switcher,
.ol-draw,
.ol-measure { 
  left: 32px; 
}

.ol-reset,
.ol-zoom {
  right: 32px;
}

@media only screen and (max-width: 960px) {
  .ol-search,
  .ol-mouse-position, 
  .ol-mouse-position,
  .ol-scale-line,
  .ol-scale-text,
  .ol-layer-switcher,
  .ol-locate,
  .ol-measure { 
    left: 4px; 
  }

  .ol-reset,
  .ol-info,
  .ol-zoom {
    right: 4px;
  }
}