
.search-field {
    margin: 0.25rem 0 0.25rem 0 ;
    width: 95%;
}

.search {
    border-radius: 2rem;
    background: #005AA3;
    color: white;
    font-weight: 400;
    min-width: 92px;
}
